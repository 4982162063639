<template>
  <div class="container">
    <h4 class="text-center my-4">Список мероприятий</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">
      <DataTable :value="events" showGridlines stripedRows
                 :globalFilterFields="['name']"
                 v-model:filters="filters"
                 :paginator="true"
                 :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск мероприятия"/>
            </span>
          </div>
        </template>
        <Column header="№" :style="{width: '60px'}">
          <template #body="{index}">
            {{ index + 1 }}
          </template>
        </Column>
        <Column field="name" header="Название мероприятия"></Column>
        <Column field="organization_name" header="Организация"></Column>
        <Column header="Место проведения">
          <template #body="{data}">
            <p v-if="data.is_room === String(1)">{{ data.room }}</p>
            <p v-else>{{ data.location }}</p>
          </template>
        </Column>
        <Column header="Дата начала">
          <template #body="{data}">
            <p>{{data.start_date.substr(0, 16)}}</p>
          </template>
        </Column>
        <Column header="Дата конца">
          <template #body="{data}">
            <p>{{data.end_date.substr(0, 16)}}</p>
          </template>
        </Column>
        <Column field="count" header="Количество зарегистрированных студентов"></Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {FilterMatchMode, FilterOperator} from "primevue/api";

export default {
  name: "DispatcherEvents",
  data() {
    return {
      loading: true,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      }
    }
  },

  methods: {
    ...mapActions('securityEvents', ['GET_EVENTS_FOR_SECURITY']),
  },
  computed: {
    ...mapState('securityEvents', ['events']),

  },
  async mounted() {
    await this.GET_EVENTS_FOR_SECURITY();

    this.loading = false
  }
}
</script>

<style scoped>

</style>